import Vue from 'vue'
import Router from 'vue-router'
import axiosObject from "../plugins/axios";
import { userStore } from "../store";
Vue.use(Router);

// eslint-disable-next-line no-unused-vars
const requireAuth = (to, from, next) => {

    const store = userStore();
    if (!store.token) {
        store.setIncomingUrl(to.fullPath);
        next({ name: 'login' });
    } else {
        axiosObject.defaults.headers.token = store.token;
        axiosObject.post("/auth/token", {
            token: store.token
            // eslint-disable-next-line no-unused-vars
        }).then(response => {
            // console.log(response.status);
            next();
            // eslint-disable-next-line no-unused-vars
        }).catch(error => {
            store.unsetUserState();
            store.tokenUnSet();
            next({ name: 'login' });
        });
    }
}

// eslint-disable-next-line no-unused-vars
const getUser = (to, from, next) => {

    const store = userStore();
    if (store.user) {
        if (store.token) {
            next({ path: '/' });
        } else {
            next();
        }
    } else {
        next({ name: 'login' });
    }
}

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: () => import('../views/admin/layouts/AdminSkeleton'),
            beforeEnter: requireAuth,
            children: [
                {
                    path: '',
                    name: 'index',
                    meta: {
                        title: "Index",
                        show: false
                    },
                    component: () => import('../views/admin/page/HomePage/Index'),
                },
                {
                    path: '/sales',
                    meta: {
                        title: "Sales",
                        icon: "sales.png",
                        show: false
                    },
                    component: () => import(/* webpackChunkName: "sales" */ '../views/admin/page/SalesPage/Index'),
                    redirect: { name: 'sales' },
                    children: [
                        {
                            path: 'saleorder',
                            name: 'sales',
                            meta: {
                                title: "Sales Order",
                                show: true
                            },
                            component: () => import(/* webpackChunkName: "sales" */ '../views/admin/page/SalesPage/SaleOrder'),
                        }, {
                            path: 'calendar',
                            name: 'sales.calendar',
                            meta: {
                                title: "Calendar View",
                                show: true
                            },
                            component: () => import(/* webpackChunkName: "sales" */ '../views/admin/page/SalesPage/Calendar'),
                        },
                        {
                            path: 'form',
                            name: 'sales.form',
                            meta: {
                                title: "Form",
                                show: false
                            },
                            component: () => import(/* webpackChunkName: "sales" */ '../views/admin/page/FormPage/Index'),
                        }
                    ]
                },
                {
                    path: '/operation',
                    meta: {
                        title: "Operation",
                        icon: "operation.png",
                        show: true
                    },
                    component: () => import(/* webpackChunkName: "operation" */ '../views/admin/page/OperationPage/Index'),
                    redirect: { name: 'operation' },
                    children: [
                        {
                            path: 'dashboard',
                            name: 'operation',
                            meta: {
                                title: "Dashboard",
                                show: true
                            },
                            component: () => import(/* webpackChunkName: "operation" */ '../views/admin/page/OperationPage/Dashboard'),
                        },
                        {
                            path: 'detail/:id',
                            name: 'operationdetail',
                            meta: {
                                title: "Operation Detail",
                                show: false
                            },
                            props: true,
                            component: () => import(/* webpackChunkName: "operation" */ '../views/admin/page/OperationPage/OperationDetail'),
                        }
                    ]
                },
                {
                    path: '/calendar',
                    meta: {
                        title: "Calendar",
                        icon: "calendar.png",
                        show: true
                    },
                    component: () => import(/* webpackChunkName: "sales" */ '../views/admin/page/SalesPage/Index'),
                    redirect: { name: 'sales.calendar' }
                },
                {
                    path: '/accounting',
                    meta: {
                        title: "Accounting",
                        icon: "accounting.png",
                        show: true
                    },
                    component: () => import(/* webpackChunkName: "accounting" */ '../views/admin/page/Accounting/Index'),
                    redirect: { name: 'customerinvoice' },
                    children: [
                        {
                            path: 'customerinvoice',
                            name: 'customerinvoice',
                            meta: {
                                title: "Customer Invoice",
                                show: true
                            },
                            component: () => import(/* webpackChunkName: "accounting" */'../views/admin/page/Accounting/CustomerInvoice'),
                        },
                        {
                            path: 'customerinvoice/:id/:action',
                            name: 'customerinvoicedetail',
                            meta: {
                                title: "Customer Invoice Detail",
                                show: false
                            },
                            props: true,
                            component: () => import(/* webpackChunkName: "accounting" */'../views/admin/page/Accounting/CustomerInvoiceDetail'),
                        },
                    ]
                },
                {
                    path: '/purchase',
                    meta: {
                        title: "Purchase",
                        icon: "purchase.png",
                        show: true
                    },
                    component: () => import(/* webpackChunkName: "purchase" */ '../views/admin/page/PurchasesPage/Index'),
                    redirect: { name: 'purchaserequisition' },
                    children: [
                        {
                            path: 'purchaserequisition',
                            name: 'purchaserequisition',
                            meta: {
                                title: "Purchase Requisition",
                                show: true
                            },
                            component: () => import(/* webpackChunkName: "purchase" */ '../views/admin/page/PurchasesPage/PurchaseRequisition'),
                        },
                        {
                            path: 'purchaserequisition/:id',
                            name: 'purchaserequisitiondetail',
                            meta: {
                                title: "Purchase Requisition Detail",
                                show: false
                            },
                            props: true,
                            component: () => import(/* webpackChunkName: "purchase" */ '../views/admin/page/PurchasesPage/PurchaseRequisitionDetail'),
                        }
                    ]
                },
                {
                    path: '/purchaseorder',
                    meta: {
                        title: "Purchase Order",
                        icon: "purchase-order.png",
                        show: true
                    },
                    component: () => import(/* webpackChunkName: "purchase" */ '../views/admin/page/PurchasesPage/Index'),
                    redirect: { name: 'purchaseorder' },
                    children: [
                        {
                            path: 'purchaseorder',
                            name: 'purchaseorder',
                            meta: {
                                title: "Purchase Order",
                                show: true
                            },
                            component: () => import(/* webpackChunkName: "purchase" */ '../views/admin/page/PurchasesPage/PurchaseOrder'),
                        },
                        {
                            path: 'purchaseorder/:id/:action',
                            name: 'purchaseorderdetail',
                            meta: {
                                title: "Purchase Order Detail",
                                show: false
                            },
                            props: true,
                            component: () => import(/* webpackChunkName: "purchase" */ '../views/admin/page/PurchasesPage/PurchaseOrderDetail'),
                        }
                    ]
                },
                {
                    path: '/warehouse',
                    meta: {
                        title: "Warehouse",
                        icon: "inventory.png",
                        show: true
                    },
                    component: () => import(/* webpackChunkName: "warehouse" */ '../views/admin/page/Warehouse/Index'),
                    redirect: { name: 'receivinggood' },
                    children: [
                        {
                            path: 'receivinggood',
                            name: 'receivinggood',
                            meta: {
                                title: "Receiving Good",
                                show: true
                            },
                            component: () => import(/* webpackChunkName: "warehouse" */ '../views/admin/page/Warehouse/ReceivingGood'),
                        },
                        {
                            path: 'receivinggood/:id/:action',
                            name: 'receivinggooddetail',
                            meta: {
                                title: "Receiving Good Detail",
                                show: false
                            },
                            props: true,
                            component: () => import(/* webpackChunkName: "warehouse" */ '../views/admin/page/Warehouse/ReceivingGoodDetail'),
                        }
                    ]
                },
                {
                    path: '/settings',
                    meta: {
                        title: "Token",
                        icon: "token.png",
                        show: false
                    },
                    component: () => import(/* webpackChunkName: "token" */ '../views/admin/page/TokenPage/Index'),
                    redirect: { name: 'token' },
                    children: [
                        {
                            path: 'token',
                            name: 'token',
                            meta: {
                                title: "Token",
                                show: true
                            },
                            component: () => import(/* webpackChunkName: "token" */ '../views/admin/page/TokenPage/components/TableComponent'),
                        },
                        {
                            path: 'create',
                            name: 'token.create',
                            meta: {
                                title: "Token",
                                show: false
                            },
                            component: () => import(/* webpackChunkName: "token" */ '../views/admin/page/TokenPage/components/FormComponent'),
                            props: false
                        },
                        {
                            path: 'edit/:id',
                            name: 'token.edit',
                            meta: {
                                title: "Token",
                                show: false
                            },
                            component: () => import(/* webpackChunkName: "token" */ '../views/admin/page/TokenPage/components/FormComponent'),
                            props: false
                        },
                    ]
                },
                {
                    path: '/register',
                    meta: {
                        title: "Register User",
                        icon: "account.png",
                        show: false
                    },
                    component: () => import(/* webpackChunkName: "token" */ '../views/admin/page/Register/Index'),
                    redirect: { name: 'register' },
                    children: [
                        {
                            path: 'register',
                            name: 'register',
                            meta: {
                                title: "Register",
                                show: true
                            },
                            component: () => import(/* webpackChunkName: "token" */ '../views/admin/page/Register/Register'),
                        },
                    ]
                },
                {
                    path: '/tracking',
                    meta: {
                        title: "Tracking Purchase",
                        icon: "tracking.png",
                        show: true
                    },
                    component: () => import(/* webpackChunkName: "token" */ '../views/admin/page/Tracking/Index'),
                    redirect: { name: 'tracking' },
                    children: [
                        {
                            path: 'tracking',
                            name: 'tracking',
                            meta: {
                                title: "Tracking Purchase",
                                show: true
                            },
                            component: () => import(/* webpackChunkName: "token" */ '../views/admin/page/Tracking/TrackingPurchase'),
                        },
                        {
                            path: 'tracking/:id',
                            name: 'trackingdetail',
                            meta: {
                                title: "Tracking Purchase Detail",
                                show: false
                            },
                            props: true,
                            component: () => import(/* webpackChunkName: "token" */ '../views/admin/page/Tracking/TrackingDetail'),
                        },
                    ]
                },
            ]
        },
        {
            path: '/login',
            component: () => import('../views/auth/page/Login'),
            name: 'login'
        },
        {
            path: '/otp',
            component: () => import('../views/auth/page/Otp'),
            beforeEnter: getUser,
            name: 'otp'
        },
        { 
            path: "*", 
            redirect: { 
                name: 'login' 
            } 
        }
    ],
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
});

// eslint-disable-next-line no-unused-vars
// router.beforeEach((to, from, next) => {
//     next();
// });

export default router