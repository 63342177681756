/* eslint-disable no-console */

const link = {
  // dev: "http://192.168.1.180:8000/api",
  // dev: "http://192.168.85.129:8000/api",
  dev: "http://be-tms.smtjetty.my.id/api",
  prod: "http://hostname/api",
  // map: "http://192.168.1.180/jettymap/"
  map: "http://map-tms.smtjetty.my.id/index.html"
}

export default link